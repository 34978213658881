<!--乡宁直聘-->
<template>
  <div class="recruit">
    <!-- <div class="searchbox w-1400">
      <div class="iptview">
        <div class="iptinner">
          <el-input
            @keydown.enter.native="getPageList"
            v-model="pageParams.filter.jobTitle"
            clearable
            placeholder="搜索职位"
            style="width: 480px"
          ></el-input>
        </div>
        <el-button type="primary" @click="getPageList">搜索</el-button>
      </div>
      <div class="hotlist clearfix">
        <h3 class="fl">热门职位：</h3>
        
        <ul class="fl">
          <li v-for="item in hotposition"  :key="item"
          :class="{ isactive: pageParams.filter.jobTitle==item }" 
          @click="pageParams.filter.jobTitle = item;getPageList();">{{item}}</li>
        </ul>
      </div>
    </div> -->
    <!-- <div class="banner-line"></div> -->
    <!-- <div class="banner-line">求职专属通道，已为89万用户提供帮助</div> -->
    <div class="w-1400 clearfix r-title">
      <!-- <ul class="fl">
        <li  v-for="item in tuijianpageData" :key="item" 
          :class="{ isactive: pageParams.filter.jobTitle}" 
          @click="pageParams.filter.jobTitle = '销售';getPageList();">热门职位</li>
        <li :class="{ isactive: !pageParams.filter.industry}"
          @click="pageParams.filter.industry = '';getPageList();">最近职位</li>
       <li :class="{ act: !activeTitle }" @click="activeTitle = 0">热门职位</li>
        <li :class="{ act: activeTitle }" @click="activeTitle = 1">最近职位</li> 
      </ul> -->
    </div>
    <div class="params-box w-1400 bg-f">
      <div class="clearfix">
        <label class="fl">行业</label>
        <i class="fl" style="margin-right: 20px">:</i>
        <div
          class="isactive fl"
          :class="{ isactive: !pageParams.filter.industry }"
          @click="
            pageParams.filter.industry = '';
            getPageList();
          "
        >
          不限
        </div>
        <ul class="clearfix fl search-list">
          <li
            v-for="item in position"
            class="fl classify-item"
            :key="item"
            :class="{ isactive: pageParams.filter.industry == item }"
            @click="
              pageParams.filter.industry = item;
              getPageList();
            "
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="clearfix">
        <label class="fl">地区</label>
        <i class="fl" style="margin-right: 20px">:</i>
        <div
          class="isactive fl"
          :class="{ isactive: !pageParams.filter.regionCode }"
          @click="
            pageParams.filter.regionCode = 0;
            getPageList();
          "
        >
          不限
        </div>
        <ul class="clearfix fl search-list">
          <li
            v-for="item in region"
            class="fl classify-item"
            :key="item.code"
            :class="{ isactive: pageParams.filter.regionCode == item.code }"
            @click="
              pageParams.filter.regionCode = item.code;
              getPageList();
            "
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="clearfix">
        <label class="fl">结算方式</label>
        <i class="fl" style="margin-right: 20px">:</i>
        <div
          class="isactive fl"
          :class="{ isactive: !pageParams.filter.settlementMode }"
          @click="
            pageParams.filter.settlementMode = '';
            getPageList();
          "
        >
          不限
        </div>
        <ul class="clearfix fl search-list">
          <li
            v-for="item in payType"
            class="fl classify-item"
            :key="item"
            :class="{ isactive: pageParams.filter.settlementMode == item }"
            @click="pageParams.filter.settlementMode=item;getPageList();"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
    <div class="w-1400 sort bg-f">
      <div>
        排序方式：
        <span class="rank act"  @click="clearParams">默认排序</span>
      </div>
    </div>
    <div class="rec-list w-1400">
      <el-row :gutter="32">
        <el-col :span="19" >
          <div v-if="pageData.list&&pageData.list.length>0">
            <el-row :gutter="15">
              <el-col
                :span="12"
                v-for="item in pageData.list"
                :key="item.id"
              >
                <router-link :to="`/recruitdetail?id=${item.id}`">
                  <div class="card bg-f">
                    <div class="ps1">
                      <span class="job">{{ item.jobTitle }}</span>
                      <span class="time"
                        >[{{
                          item.createdTime && item.createdTime.substr(0, 10)
                        }}]</span
                      >
                      <span class="howpay">{{ item.salary }}</span>
                    </div>
                    <div class="c-6">
                      {{
                        item.education == "不限" ? "学历不限" : item.education
                      }}/{{
                        item.experience == "不限" ? "经验不限" : item.experience
                      }}
                    </div>
                    <div class="enterprise-info clearfix">
                      <div class="fl card-pic">
                        <el-image
                          style="width: 100%; height: 100%"
                          :src="baseUrl + item.photoUrls"
                        ></el-image>
                      </div>
                      <div class="fr card-info">
                        <h5>{{ item.companyName }}</h5>
                        <p class="ellipsis">
                          {{ item.jobMode }}/招收{{ item.recruitNumber }}人/{{
                            item.regionName
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </router-link>
              </el-col>
            </el-row>
            <el-pagination
              class="pagination"
              background
              layout="prev, pager, next,jumper"
              :total="pageData.total"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
          <div class="empty" v-else>
            <el-empty description="暂无数据"></el-empty>
          </div>
        </el-col>
        <el-col :span="5" >
          <div v-if="mingqipageData&&mingqipageData.length>0">
            <div class="mainr-title">行业名企</div>
            <div
              class="en-item bg-f"
              v-for="item in mingqipageData"
              :key="item.id + '222'"
            >
            <router-link :to="`/recruitdetail?id=${item.id}`">
                <div class="r-pic">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="baseUrl + item.photoUrls"
                    ></el-image>
                </div>
                <div class="mainr-name">{{ item.industry }}</div>
                <div class="mainr-text ellipsis">{{ item.jobDescription }}</div>
            </router-link>
            </div>
          </div>
          <div class="empty" v-else>
            <!-- <el-empty description="暂无数据"></el-empty> -->
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { GetXnrecruitInfos } from "@/api/xiangningwang";
export default {
  data() {
    return {
      hotposition:["乡村规划师","服务员","运营","保安"],//["室内设计师","采购","销售"]
      position: [
        "餐饮",
        "园艺",
        "技术员",
      ],
      // position: [
      //   "餐饮",
      //   "建筑",
      //   "医疗",
      //   "美容",
      //   "销售",
      //   "设计",
      //   "安保",
      //   "其他",
      // ],
      region: [
        //地区
        // { name: "全部", code: 0 },
        { name: "大通县", code: 630121 },
        { name: "湟源县", code: 630123 },
        { name: "湟中区", code: 630122 },
        { name: "城东区", code: 630102 },
        { name: "城中区", code: 630103 },
        { name: "城西区", code: 630104 },
        { name: "城北区", code: 630105 },
      ],
      payType: ["日结", "周结", "月结", "完工结算"],
      searchData: { region: "西宁市" },
      activeTitle: 0,
      pageParams: {
        currentPage: 1,
        filter: {
          regionCode: 0,
        },
        pageSize: 10,
      },
      pageData: {},
      activeTitle:2,
      mingqipageData: {},
    };
  },
  computed: {
    baseUrl: () => process.env.VUE_APP_PHOTO_URL,
  },
  methods: {
    getPageList() {
      GetXnrecruitInfos(this.pageParams).then((res) => {
        this.pageData = res.data.data;
        this.mingqipageData = res.data.data.list.slice(0, 4);
      });
    },
    //分页
    handleCurrentChange(page) {
      this.pageParams.currentPage = page;
      this.getPageList();
    },
    clearParams() {
      this.pageParams = {
        currentPage: 1,
        pageSize: 10,
        filter: {
         hotposition:"",
         region:"",
         position:"",
         payType:"",
        },
      };
      this.getPageList();
    },
  },
  mounted() {
    this.getPageList();
  },
};
</script>
<style scoped>
.rank{
    cursor: pointer;
    color: #0e6ac1;
}
.en-item:not(.en-item:last-child) {
  border-bottom: 2px solid #777;
}
.mainr-text {
  font-size: 18px;
  color: #666;
  text-align: center;
}
.mainr-name {
  font-size: 20px;
  color: #0e6ac1;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-weight: 600;
}
.en-item.bg-f {
  padding: 30px 20px;
}
.mainr-title {
  height: 50px;
  line-height: 50px;
  font-size: 22px;
  color: #fff;
  background: #f03442;
  text-align: center;
}
.r-pic {
  width: 140px;
  height: 140px;
  overflow: hidden;
  margin: 0 auto;
}
.card-info {
  width: calc(100% - 95px);
  height: 80px;
  overflow: hidden;
  line-height: 40px;
  font-size: 16px;
}
.card-pic {
  height: 80px;
  width: 80px;
}
.enterprise-info {
  padding: 15px;
  margin-top: 15px;
  border-top: 2px solid #e4e7ed;
}
.howpay {
  color: #f03442;
}
.c-6 {
  font-size: 16px;
  line-height: 30px;
}
.time,
.c-6 {
  color: #666;
}
.job {
  font-weight: 600;
}
.ps1 {
  height: 32px;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}
.card {
  padding: 20px 10px;
  margin-bottom: 20px;
}
.sort {
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
}
.sort span {
  margin-right: 20px;
  font-weight: 400;
  padding: 0 10px;
}
.sort span.act {
  background: #0169ca;
  color: #fff;
}
.bg-f {
  background: #fff;
  padding: 10px;
}
.classify-item {
  padding: 0px 20px;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}
.params-box span.isactive,
.v-list-sort span.isactive,
.isactive {
  color: #4283d2;
}
.params-box {
  margin-top: 20px;
  /* font-family: '宋体'; */
}
.params-box div {
  font-size: 18px;
  font-weight: 500;
  /* height: 40px; */
  line-height: 40px;
}
.params-box label {
  width: 100px;
  text-align: justify;
  text-align-last: justify;
  font-weight: 550;
}
.search-list {
  width: 700px;
}
.r-title .more {
  cursor: pointer;
}
/* .r-title {
  font-size: 18px;
  border-bottom: 1px solid #777;
  height: 40px;
  line-height: 40px;
  position: relative;
} */
.r-title ul li.act {
  border-bottom: 3px solid #0169ca;
  color: #0169ca;
}
.r-title ul li {
  margin-right: 22px;
  cursor: pointer;
  padding: 0 5px;
  font-weight: 600;
}
.r-title ul {
  display: flex;
  position: absolute;
  top: -2.5px;
  left: 0;
}
.banner-line {
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  background: #0169ca;
}
.hotlist ul li {
  margin: 0 10px;
  cursor: pointer;
}
.hotlist ul {
  display: flex;
  color: #777;
}
.hotlist {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
}
.iptinner {
  border: 1px solid #666;
}
.iptview >>> .el-button--primary {
  background-color: #0169ca;
  border-color: #0169ca;
}
.iptview >>> .el-input__inner {
  border-color: #0169ca;
  border-radius: 0;
}
.iptview >>> .el-button {
  border-radius: 0;
  /* transform: translateY(-1px); */
}
.iptview,
.hotlist {
  display: flex;
  width: 672px;
  margin: 0 auto;
}
.recruit {
  font-family: "微软雅黑";
  background: #f6f6f8;
  padding-bottom: 20px;
}
</style>